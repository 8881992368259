<template>
  <div class="home">
    <section id="screen" class="hero is-fullheight">
      <div class="hero-body">
        <div id="background" class="fixed-parallax"></div>
        <div class="container has-text-centered">
          <img id="logo-padel" alt="logo" src="../assets/logo.png" />
          <h2 id="logo-padel-subtitle" class="subtitle has-text-black mt-5">
            Il primo centro
            <p class="has-text-danger-dark is-inline">Padel</p>
            dell’Altovicentino!
          </h2>
        </div>
      </div>
    </section>

    <section class="mb-6" style="margin-top: -10em">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column mx-5">
            <div data-aos="zoom-in-right" class="box has-shadow-dark rounded">
              <article class="media">
                <!-- <div class="media-left">
                  <figure class="image is-32x32 is-flex">
                    <img src="../assets/about.svg" />
                  </figure>
                </div>-->
                <div class="media-content">
                  <div class="is-italic">
                    <div>
                      <strong>Ie Tron</strong>
                      <small class="is-italic ml-2">recensioni Google</small>
                      <br class="is-hidden-desktop" />
                      <span class="mx-3"
                        ><i
                          v-for="x in 5"
                          :key="x"
                          class="fa fa-star is-inline mx-1"
                        ></i
                      ></span>
                      <br />
                      <div class="mt-2"></div>
                      Quello che mancava nell'alto vicentino...
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <div class="column mx-5">
            <div data-aos="zoom-in-left" class="box has-shadow-dark rounded">
              <article class="media">
                <!-- <div class="media-left">
                  <figure class="image is-64x64 is-flex">
                    <img
                      class="is-flex justify-content-middle"
                      src="../assets/about.svg"
                    />
                  </figure>
                </div>-->
                <div class="media-content">
                  <div class="is-italic">
                    <div>
                      <strong>Doge DJ</strong>
                      <small class="is-italic ml-2">recensioni Google</small>
                      <br class="is-hidden-desktop" />
                      <span class="mx-3"
                        ><i
                          v-for="x in 5"
                          :key="x"
                          class="fa fa-star is-inline mx-1"
                        ></i
                      ></span>
                      <br />
                      <div class="mt-2">
                        Bellissima struttura per praticare il Padel.<br />Personale
                        gentilissimo e molto disponibile.
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="px-5 py-3">
      <div class="is-relative container">
        <div
          id="responsive_headline_welcome"
          class="background-text has-text-grey is-uppercase is-italic"
        >
          Padel world
        </div>
        <div class="mb-6 pb-3 columns is-multiline">
          <div
            data-aos="zoom-in"
            class="column is-12 is-6-desktop mx-auto has-text-centered"
          >
            <h2 class="mb-4 is-size-1 is-size-3-mobile has-text-weight-bold">
              Benvenuto in
              <p class="has-text-danger-dark is-inline">PadelSpot</p>
            </h2>
            <p class="subtitle has-text-grey mb-2">
              Abbiamo aperto il nostro primo centro vicino a te!
              <br />Raggiungibile da qualsiasi zona dell’Altovicentino, ci trovi
              in Viale dell’Industria n°108, Schio (VI)
              <br />
              <router-link to="/reserve">
                <a class="button is-rounded is-large is-danger mt-5">
                  <strong class="has-text-white">
                    Prenota
                    <span class="icon is-small ml-1">
                      <i class="fas fa-chevron-right"></i>
                    </span>
                  </strong>
                </a>
              </router-link>
            </p>
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="column is-12 is-4-desktop">
            <div
              data-aos="fade-right"
              class="mb-6 is-flex is-horizontal-center"
            >
              <span>
                <div
                  class="button image is-48x48 is-outlined is-danger is-circular"
                >
                  <span class="icon pt-2">
                    <i class="fas fa-baseball-ball"></i>
                  </span>
                </div>
              </span>
              <div class="ml-3">
                <h4 class="is-size-4 has-text-weight-bold mb-2">
                  3 campi da Padel indoor
                </h4>
                <p class="subtitle has-text-grey px-3">
                  Campi panoramici del WORLD PADEL TOUR
                </p>
              </div>
            </div>
            <div
              data-aos="fade-right"
              class="mb-6 is-flex is-horizontal-center"
            >
              <span>
                <div
                  class="button image is-48x48 is-outlined is-danger is-circular"
                >
                  <span class="icon pt-2">
                    <i class="fas fa-play"></i>
                  </span>
                </div>
              </span>
              <div class="ml-3">
                <h4 class="is-size-4 has-text-weight-bold mb-2">
                  Noleggio e acquisto racchette + acquisto palline
                </h4>
                <p class="subtitle has-text-grey px-3">
                  Troverai nel nostro centro una vasta gamma di racchette e
                  palline da noleggiare o acquistare
                </p>
              </div>
            </div>
            <div
              data-aos="fade-right"
              class="mb-6 is-flex is-horizontal-center"
            >
              <span>
                <div
                  class="button image is-48x48 is-outlined is-danger is-circular"
                >
                  <span class="icon pt-2">
                    <i class="fas fa-clipboard-check"></i>
                  </span>
                </div>
              </span>
              <div class="ml-3">
                <h4 class="is-size-4 has-text-weight-bold mb-2">
                  Prenotazione Online
                </h4>
                <p class="subtitle has-text-grey px-3">
                  Una volta tesserato potrai prenotare comodamente dal nostro
                  sito, ovunque tu sia
                </p>
              </div>
            </div>
          </div>
          <div
            data-aos="zoom-in"
            class="column is-4 is-block-desktop is-hidden-touch"
          >
            <img
              class="mx-auto mt-0-widescreen is-block image"
              src="../assets/logo_icon_color_dark.png"
              alt="icon"
            />
          </div>
          <div class="column is-12 is-4-desktop">
            <div data-aos="fade-left" class="mb-6 is-flex is-horizontal-center">
              <span>
                <div
                  class="button image is-48x48 is-outlined is-danger is-circular"
                >
                  <span class="icon pt-2">
                    <i class="fas fa-chalkboard-teacher"></i>
                  </span>
                </div>
              </span>
              <div class="ml-3">
                <h4 class="is-size-4 has-text-weight-bold mb-2">
                  Istruttori Padel
                </h4>
                <p class="subtitle has-text-grey px-3">
                  Contattaci per organizzare una lezione privata con i nostri
                  istruttori
                </p>
              </div>
            </div>
            <div data-aos="fade-left" class="mb-6 is-flex is-horizontal-center">
              <span>
                <div
                  class="button image is-48x48 is-outlined is-danger is-circular"
                >
                  <span class="icon pt-2">
                    <i class="fas fa-shower"></i>
                  </span>
                </div>
              </span>
              <div class="ml-3">
                <h4 class="is-size-4 has-text-weight-bold mb-2">
                  Spogliatoi e docce
                </h4>
                <p class="subtitle has-text-grey px-3">
                  Sei di corsa? Potrai utilizzare spogliatoi e docce del centro
                  PadelSpot
                </p>
              </div>
            </div>
            <div data-aos="fade-left" class="mb-6 is-flex is-horizontal-center">
              <span>
                <div
                  class="button image is-48x48 is-outlined is-danger is-circular"
                >
                  <span class="icon pt-2">
                    <i class="fas fa-utensils"></i>
                  </span>
                </div>
              </span>
              <div class="ml-3">
                <h4 class="is-size-4 has-text-weight-bold mb-2">
                  Area Ristoro
                </h4>
                <p class="subtitle has-text-grey px-3">
                  Potrai recuperare tutte le energie dopo una partita!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div
      v-if="banner != null && banner.enabled"
      class="hero is-small"
      v-bind:class="{
        'is-warning': banner.color == 'warning',
        'is-success': banner.color == 'success',
        'is-danger': banner.color == 'danger',
        'is-info': banner.color == 'info',
        'is-dark': banner.color == 'dark',
        'is-light': banner.color == 'light',
      }"
    >
      <div class="hero-body">
        <div class="container has-text-centered">
          <p
            class="tag is-rounded mr-2"
            v-bind:class="{
              'is-dark': banner.color == 'light',
              'is-light': banner.color != 'light',
            }"
          >
            {{ banner.tag }}
          </p>
          {{ banner.message }}
        </div>
      </div>
    </div>

    <!-- <div id="showcase" class="fixed-parallax"></div> -->

    <section class="has-background-dark">
      <div class="is-relative">
        <div
          id="responsive_headline_join"
          class="background-text has-text-grey is-uppercase is-italic"
        >
          # J o i n
        </div>
        <div class="container my-3">
          <div class="columns is-vcentered container reverse-row-order py-6">
            <div class="column mx-6">
              <h1 class="title has-text-light is-italic is-uppercase">
                Prenota un campo
              </h1>
              <div class="has-text-light mb-5">
                <span class="has-text-grey-light"
                  >Sei un nostro Tesserato?</span
                >
                <br />1. Accedi al tuo profilo <br />2. Clicca su prenota
                <br />3. Scegli l’orario più adatto a te e ai tuoi amici
                <br />
                <br />
                <span class="has-text-grey-light"
                  >Non sei ancora un Tesserato Padelspot?</span
                >
                <br />1. Vedi gli orari disponibili premendo il tasto "Prenota"
                <br />2. Scrivici su
                <a
                  href="https://api.whatsapp.com/send?phone=+393890277189&text=Ciao%21+Vorrei+effettuare+la+prima+prenotazione+ma+non+sono+ancora+tesserato.+Come+posso+fare%3F"
                  class="has-text-danger"
                  target="_blank"
                  >Whatsapp</a
                >
                <!-- o chiamaci al numero
                <a href="tel:3890277189" class="has-text-danger">3890277189</a>-->
                indicando giorno ed orario. Se disponibile verrai avvisato con
                un messaggio di conferma
                <br />
                <br />
                <strong class="is-italic has-text-grey-light">
                  NB : una volta tesserato potrai anche tu prenotare
                  direttamente dal nostro sito!
                </strong>
                <br />
                <br />
                <div class="card has-background-dark has-border-light p-2">
                  <small>
                    <strong class="has-text-light is-italic">
                      <span class="is-underlined">E' necessario inoltre:</span>
                      <br />- Super green Pass <br />- Visita medica valida da
                      consegnare al nostro ufficio <br />- Verifica della
                      prenotazione online
                    </strong>
                  </small>
                </div>
              </div>
              <router-link to="/reserve">
                <a class="button is-rounded is-danger">
                  <strong>
                    Prenota
                    <span class="icon is-small ml-1">
                      <i class="fas fa-chevron-right"></i>
                    </span>
                  </strong>
                </a>
              </router-link>
            </div>
            <div class="column mx-5 py-5">
              <img
                alt="campo"
                data-aos="fade-left"
                class="image aspect-ratio rounded has-border-light has-shadow-light"
                src="../assets/photo2.jpeg"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- <hr class="container has-background-grey-dark" /> -->

      <div class="has-background-grey-dark is-relative">
        <div
          id="responsive_headline_padel"
          class="background-text has-text-grey is-uppercase is-italic"
        >
          P a d e l
        </div>
        <div class="container my-3">
          <div class="columns is-vcentered container py-6">
            <div class="column mx-5 py-5">
              <img
                alt="centro"
                data-aos="fade-right"
                class="image aspect-ratio rounded has-border-light has-shadow-light"
                src="../assets/photo1.jpeg"
              />
            </div>
            <div class="column mx-6">
              <h1 class="title has-text-light is-italic is-uppercase">
                I nostri centri
              </h1>
              <p class="has-text-light mb-5">
                Vedi i nostri centri e tutto quello di cui sono attrezzati.
                <br />Scopri orari, prezzi e dove ci troviamo!
              </p>
              <router-link to="/about">
                <a class="button is-rounded is-danger">
                  <strong>
                    Scopri
                    <span class="icon is-small ml-1">
                      <i class="fas fa-chevron-right"></i>
                    </span>
                  </strong>
                </a>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- <hr class="container has-background-grey-dark" /> -->

      <div class="is-relative">
        <div
          id="responsive_headline_spot"
          class="background-text has-text-grey is-uppercase is-italic"
        >
          S p o t
        </div>
        <div class="container my-3">
          <div class="columns is-vcentered container reverse-row-order py-6">
            <div class="column mx-6">
              <h1 class="title has-text-light is-italic is-uppercase">
                Seguici nei social
              </h1>
              <p class="has-text-light mb-5">
                Seguici su
                <a
                  target="_blank"
                  rel="noopener"
                  class="has-text-danger"
                  href="https://www.instagram.com/padelspot.it/"
                  >Instagram</a
                >
                &amp;
                <a
                  target="_blank"
                  rel="noopener"
                  class="has-text-danger"
                  href="https://www.facebook.com/Padelspot-106560744957667"
                  >Facebook</a
                >. <br />Tieniti aggiornato sulle novità seguendoci tramite la
                sezione eventi.
              </p>
              <router-link to="/events">
                <a class="button is-rounded is-danger">
                  <strong>
                    Vedi le novità
                    <span class="icon is-small ml-1">
                      <i class="fas fa-chevron-right"></i>
                    </span>
                  </strong>
                </a>
              </router-link>
            </div>
            <div class="column mx-5 py-5">
              <img
                alt="campo"
                data-aos="fade-left"
                class="image aspect-ratio rounded has-border-light has-shadow-light"
                src="../assets/photo3.jpeg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <div class="has-background-dark">
      <hr class="container has-background-grey-dark" />
    </div>-->

    <div class="is-small has-background-grey-dark py-6">
      <div class="title is-3 is-italic is-uppercase has-text-danger">
        I nostri sponsor
      </div>
      <!-- mobile -->
      <div
        v-for="(url, index) in sponsorsImagesUrls"
        v-bind:key="index"
        class="my-3 is-horizontal-center is-inline is-hidden-desktop"
      >
        <img
          v-if="url"
          alt="sponsor"
          class="image m-4 sponsor greyscale is-inline"
          :src="url"
        />
      </div>
      <!-- desktop -->
      <div id="marquee-wrapper" class="marquee-wrapper is-hidden-touch">
        <div class="marquee-block">
          <div class="marquee-inner to-left">
            <span>
              <div
                v-for="(url, index) in sponsorsImagesUrls"
                v-bind:key="index"
                class="marquee-item my-3 is-horizontal-center is-inline"
              >
                <img
                  v-if="url"
                  alt="sponsor"
                  class="image px-5 sponsor greyscale is-inline"
                  :src="url"
                />
              </div>
            </span>
            <span>
              <div
                v-for="(url, index) in sponsorsImagesUrls"
                v-bind:key="index"
                class="marquee-item my-3 is-horizontal-center is-inline"
              >
                <img
                  v-if="url"
                  alt="sponsor"
                  class="image px-5 sponsor greyscale is-inline"
                  :src="url"
                />
              </div>
            </span>
          </div>
          <!-- <div v-if="showSponsors" class="columns is-centered has-text-centered">
          <marquee style="width: 100%" scrollamount="50">
            <div
              v-for="(url, index) in sponsorsImagesUrls"
              v-bind:key="index"
              class="my-3 is-horizontal-center is-inline"
            >
              <img
                v-if="url"
                alt="sponsor"
                class="image sponsor greyscale is-inline"
                :src="url"
              />
            </div>
          </marquee> -->
          <!-- <div class="column my-3 is-horizontal-center is-flex">
            <img class="image sponsor greyscale" src="../assets/logo.png" />
          </div>-->
        </div>
      </div>
    </div>

    <section class="py-6 has-background-info">
      <div class="container">
        <div class="is-vcentered columns is-multiline">
          <div class="column px-5 is-6 is-5-desktop mb-4">
            <span class="has-text-white">Tieniti aggiornato!</span>
            <h2
              class="has-text-white mt-2 mb-3 is-size-1 is-size-3-mobile has-text-weight-bold"
            >
              Iscriviti alla nostra newsletter
            </h2>
            <p class="has-text-white">
              Potrai essere il primo a ricevere gli ultimi aggiornamenti!
            </p>
          </div>
          <div class="column px-5 is-5 ml-auto">
            <div
              data-aos="zoom-in-left"
              class="mx-auto rounded has-border-dark has-shadow-dark p-6 has-background-light has-text-centered"
            >
              <h4 class="is-size-4 mb-2 has-text-weight-bold">
                Iscriviti alla newsletter!
              </h4>
              <p class="has-text-grey-dark mb-4">
                Inviando la tua email ci permetterai di aggiornarti con le
                ultime notivà.
              </p>
              <form action="#" @submit.prevent="subscribeEmail">
                <div
                  v-if="ret.message"
                  v-bind:class="{
                    'is-danger': !ret.success,
                    'is-success': ret.success,
                  }"
                  class="notification rounded is-light mb-3"
                >
                  {{ ret.message }}
                </div>
                <p class="control has-icons-left mb-3">
                  <input
                    v-model="form.email"
                    class="input is-rounded"
                    type="email"
                    placeholder="La tua email"
                  />
                  <span class="icon is-left">
                    <i class="fas fa-envelope"></i>
                  </span>
                </p>
                <button
                  v-bind:disabled="invalidEmail || waiting"
                  v-bind:class="{ 'is-loading': waiting }"
                  class="button is-info is-rounded is-fullwidth"
                >
                  Invia
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import A from "../store/action-types.js";
import Generals from "../services/generals";
import Photos from "../services/photos.js";

export default {
  name: "Home",
  metaInfo: {
    title: "Home",
    // override the parent template and just use the above title only
    // titleTemplate: null,
  },
  data() {
    return {
      form: {
        email: "",
      },
      banner: {
        enabled: false,
        page: "Home",
        pageName: "Home",
        color: "",
        tag: "",
        message: "",
      },
      invalidEmail: true,
      waiting: false, // email form
      ret: {
        success: false,
        message: "",
      },

      waitingSponsors: true,
      errorSponsors: false,
      sponsorsImagesUrls: [],

      showSponsors: false,
    };
  },
  async created() {
    this.banner = await Generals.getBannerHome();

    // sponsors
    this.waitingSponsors = true;

    Photos.listAllSponsors()
      .then((res) => {
        res.items.forEach((itemRef, index) => {
          // All the items under listRef.
          this.sponsorsImagesUrls.push(undefined);
          Photos.getDownloadURL(itemRef.fullPath).then((value) => {
            this.sponsorsImagesUrls.splice(index, 1, value);
          });
        });
        document.getElementById("marquee-wrapper").style.minWidth =
          String(250 * res.items.length) + "px";
      })
      .catch((reason) => {
        this.errorSponsors = reason;
      })
      .finally(() => {
        this.waitingSponsors = false;
      });
  },
  mounted() {
    try {
      if (window.fitText != undefined) {
        window.fitText(
          document.getElementById("responsive_headline_welcome"),
          0.4
        );
        window.fitText(
          document.getElementById("responsive_headline_join"),
          0.4
        );
        window.fitText(
          document.getElementById("responsive_headline_padel"),
          0.4
        );
        window.fitText(
          document.getElementById("responsive_headline_spot"),
          0.4
        );
      }
    } catch (e) {
      console.warn(e);
    }
  },
  watch: {
    "form.email"(value) {
      // binding this to the data value in the email input
      // this.email = value;
      this.validateEmail(value);
    },
  },
  methods: {
    async subscribeEmail() {
      this.ret = {};
      this.waiting = true;
      this.ret = await this.$store.dispatch(A.SUBSCRIBE_EMAIL, {
        email: this.form.email,
      });
      this.waiting = false;
      this.form.email = "";
    },
    validateEmail(value) {
      if (this.$store.getters.regexEmail.test(value)) {
        this.invalidEmail = false;
      } else {
        this.invalidEmail = true;
      }
    },
  },
};
</script>

<style scoped>
#background {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* To compensate for mobile browser address bar space */
  background-image: url("../assets/photo10.jpeg");
  background-size: 100% 100%;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  display: flex;
  z-index: -1;
  filter: blur(3px);
}
@media only screen and (max-width: 1023px) {
  #background {
    background-attachment: scroll !important;
  }
}
@media only screen and (min-width: 1023px) {
  #background {
    overflow-y: hidden !important;
  }
}
#showcase {
  background-image: url("../assets/photo11.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 220px;
  filter: blur(3px);
}
.sponsor {
  max-height: 110px;
  max-width: 220px;
  background-size: cover;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#logo-padel {
  max-width: 500px;
  max-height: 200px;
}
#logo-padel-subtitle {
  text-shadow: 1px 1px 11px white, 0 0 30px black, 0 0 1px black;
}
#screen {
  -webkit-animation: fadeIn 1.5s;
  animation: fadeIn 1.5s;
}
/* marquee */
.marquee-wrapper {
  /* width: 150%; */
  text-align: center;
}
.marquee-wrapper .container {
  overflow: hidden;
}
.marquee-inner span {
  float: left;
  width: 50%;
}
.marquee-wrapper .marquee-block {
  /* --total-marquee-items: 5; */
  height: 150px;
  /* width: calc(250px * (var(--total-marquee-items))); */
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}
.marquee-inner {
  display: block;
  width: 200%;
  position: absolute;
}
.marquee-inner p {
  font-weight: 800;
  font-size: 30px;
  font-family: cursive;
}
.marquee-inner.to-left {
  animation: marqueeLeft 12s linear infinite;
}
.marquee-inner.to-right {
  animation: marqueeRight 12s linear infinite;
}
.marquee-item {
  width: 230px;
  height: auto;
  display: inline-block;
  /* float: left; */
  transition: all 0.2s ease-out;
}
@keyframes marqueeLeft {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@keyframes marqueeRight {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}
</style>
