<template>
  <div id="navbar" class="hero">
    <nav v-bind:class="{
      'is-dark': $route.path == '/',
      'is-light': $route.path != '/',
    }" class="navbar py-1" role="navigation" aria-label="main navigation">
      <div class="navbar-brand p-1">
        <router-link v-bind:class="{
          'has-background-dark': $route.name == 'Home',
          'has-background-grey-lighter': $route.name != 'Home',
        }" class="navbar-item" to="/">
          <img alt="logo" v-if="$route.name == 'Home'" src="../assets/logo_icon_color.png" />
          <img alt="logo" v-if="$route.name != 'Home'" src="../assets/logo_icon_color_dark.png" />
        </router-link>

        <a id="navbarBurger" role="button" class="navbar-burger mr-2" v-bind:class="{
          'has-border-light': $route.name == 'Home',
          'has-text-light': $route.name == 'Home',
          'has-border-dark': $route.name != 'Home',
          'has-text-dark': $route.name != 'Home',
        }" aria-label="menu" aria-expanded="false" data-target="navbarMenu">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarMenu" class="navbar-menu rounded">
        <div class="navbar-start my-2">
          <router-link v-bind:class="{ 'is-active': $route.name == 'Home' }" class="navbar-item"
            to="/">Home</router-link>

          <router-link v-bind:class="{ 'is-active': $route.name == 'Events' }" class="navbar-item"
            to="/events">Eventi<div
              v-if="$store.getters.isLogged &&
              $store.getters.isLoadingFirestoreUserData &&
              (!$store.getters.userInfo.latestSeenTimestamp || checkDates(lastNewsTimestamp, $store.getters.userInfo.latestSeenTimestamp))"
              class="tag is-danger is-rounded ml-2">
              Novità!</div>
          </router-link>

          <router-link v-bind:class="{ 'is-active': $route.name == 'Reserve' }"
            class="navbar-item" to="/reserve">Prenota</router-link>

          <router-link v-bind:class="{ 'is-active': $route.name == 'PadelStream' }"
            class="navbar-item" to="/padelstream">PadelStream
            <div v-if="(new Date().toISOString().slice(0, 10)) < '2024-02-01'" class="tag is-danger is-rounded ml-2">New!</div>
          </router-link>

          <router-link v-bind:class="{ 'is-active': $route.name == 'About' }" class="navbar-item"
            to="/about">I nostri centri</router-link>

          <!-- <div
            v-bind:class="{ 'is-active': $route.name == '' }"
            class="navbar-item has-dropdown is-hoverable"
          >
            <a class="navbar-link has-text-shadow-light">Altro</a>

            <div class="navbar-dropdown">
              <router-link class="has-text-dark navbar-item" to="/about"
                >Test</router-link
              >
            </div>
          </div> -->

          <router-link id="navbarAdmin" v-if="$store.getters.isAdmin" v-bind:class="{
            'is-active': $route.name == 'Management',
            'has-border-light': $route.name == 'Home',
            'has-border-dark': $route.name != 'Home',
          }" class="navbar-item" to="/management">Amministrazione</router-link>
        </div>

        <hr class="is-hidden-desktop my-2" />

        <div class="navbar-end">
          <!-- not logged -->
          <div class="navbar-item">
            <div v-if="!$store.getters.isLogged" class="columns">
              <router-link class="has-text-light column" to="/register"><a
                  class="button is-rounded is-danger"><strong>Registrati</strong>
                </a></router-link>

              <router-link class="has-text-info column" to="/login"><a class="button is-rounded is-outlined"
                  v-bind:class="{
                    'is-dark': $route.name == 'Home',
                    'is-dark': $route.name != 'Home',
                  }"><strong>Accedi</strong></a></router-link>
            </div>
          </div>

          <div v-if="$store.getters.isLogged" class="navbar-item">
            <!-- logged -->
            <div id="navbarProfile" class="navbar-item has-dropdown is-hoverable" v-bind:class="{
              'has-border-light': $route.name == 'Home',
              'has-border-dark': $route.name != 'Home',
            }">
              <a id="profileButton" class="navbar-link">
                <!-- pallino rosso -->
                <div v-if="$store.getters.hasAccess === false" class="circle is-inline p-2 mr-2 has-background-danger">
                </div>
                <!-- pallino giallo -->
                <div v-if="
                  $store.getters.hasAccess &&
                  !$store.getters.user.emailVerified
                " class="circle is-inline p-2 mr-2 has-background-warning"></div>
                {{ $store.getters.username }}
              </a>
              <div class="navbar-dropdown">
                <router-link class="has-text-dark navbar-item" to="/profile">Profilo</router-link>
                <hr class="navbar-divider" />
                <div id="logout-button" @click="logout" class="has-text-dark navbar-item">
                  Esci
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import A from "../store/action-types.js";

export default {
  name: "NavBar",
  data() {
    return {
      form: {
        email: "",
        password: "",
        confirmPassword: "",
      },
      invalidEmail: true,
      invalidPassword: true,
      invalidConfirmPassword: true,
      waiting: false,
      error: "",
    };
  },
  props: {
    lastNewsTimestamp: {
      required: false,
      default: null
    }
  },
  methods: {
    async logout() {
      if (
        (await this.$store.dispatch(A.LOGOUT)).success &&
        this.$route.name != "Home"
      ) {
        this.$router.replace({ name: "Home" });
      }
    },
    checkDates(lastNewsTimestamp, latestSeenTimestamp) {
      return lastNewsTimestamp > latestSeenTimestamp;
    }
  },
};
</script>

<style scoped>
#profileButton {
  min-width: 120px;
  min-height: 40px;
}

#logout-button:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.break_work {
  white-space: pre-wrap !important;
}

.navbar-item {
  border-radius: 12px;
  margin-right: 0.387rem;
  margin-left: 0.387rem;
}

.navbar-link {
  border-radius: 12px;
}

.navbar-dropdown {
  border-radius: 12px;
}

.navbar-burger {
  border-radius: 12px;
}
</style>
