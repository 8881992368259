<template>
  <div class="my-footer has-background-black p-5">
    <div class="">
      <footer class="columns has-text-light">
        <div class="column is-8 is-offset-2">
          <br />
          <nav class="level">
            <div class="level-item column is-4">
              <span class="icon">
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://www.facebook.com/Padelspot-106560744957667"
                  ><i class="fab has-text-light fa-facebook"></i
                ></a>
              </span>
              &emsp;
              <span class="icon">
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://www.instagram.com/padelspot.it/"
                >
                  <i class="fab has-text-light fa-instagram"></i
                ></a>
              </span>
            </div>
            <small class="level-item has-text-grey column is-4">
              <small
                >Sviluppato da
                <a
                  href="https://carrettariccardo.dev"
                  target="_blank"
                  class="is-inline is-italic has-text-danger"
                  >carrettariccardo.dev</a
                >
              </small>
            </small>
            <small class="level-item column is-4">
              &copy; PadelSpot.it. Tutti i diritti riservati
              <br />
              <a
                @click.prevent="showCookieBanner = true"
                class="has-text-danger-dark"
                >Consenso Cookie</a
              >
              <br />
              <a
                class="has-text-danger-dark"
                href="https://www.privacypolicygenerator.info/live.php?token=83O9RElEfIUVXWwV6xJwEh9qDlEJ2uMH"
                target="_blank"
                >Privacy Policy</a
              >
            </small>
          </nav>
        </div>
      </footer>

      <div
        class="level columns is-mobile is-multiline has-text-centered has-background-black container mx-auto pb-6"
      >
        <div class="column is-2-desktop is-full-mobile">
          <a
            href="https://firebasestorage.googleapis.com/v0/b/padel-spot-1aa9f.appspot.com/o/AMAMI-verbale-nomina-safeguarding%20e%20tutela%20minori.pdf?alt=media&token=09be42a3-d7f4-492b-b96c-d8238cd66324"
            target="_blank"
            class="has-text-danger-dark"
            >Safeguarding</a
          >
        </div>
        <div class="column is-2-desktop is-full-mobile">
          <a
            href="https://firebasestorage.googleapis.com/v0/b/padel-spot-1aa9f.appspot.com/o/CODICE%20DI%20CONDOTTA%20Amami.pdf?alt=media&token=845c31d9-347f-4031-a99e-b6fc6b27e516"
            target="_blank"
            class="has-text-danger-dark"
            >Codice di Condotta</a
          >
        </div>
        <div class="column is-2-desktop is-full-mobile">
          <a
            href="https://firebasestorage.googleapis.com/v0/b/padel-spot-1aa9f.appspot.com/o/FITP_regolamento_contro_la_violenza_di_genere_nello_sport_cf_del_29_dicembre_2023_1.pdf?alt=media&token=9cbe03b0-d5ab-42fb-be8a-a8a37b6ace04"
            target="_blank"
            class="has-text-danger-dark"
            >Regolamento Violenza di Genere</a
          >
        </div>
        <div class="column is-2-desktop is-full-mobile">
          <a
            href="https://firebasestorage.googleapis.com/v0/b/padel-spot-1aa9f.appspot.com/o/Modello%20Organizzativo%20Amami.pdf?alt=media&token=5ba5e056-5ea6-43e0-b3ff-83389de3ad75"
            target="_blank"
            class="has-text-danger-dark"
            >Modello Organizzativo</a
          >
        </div>
        <div class="column is-2-desktop is-full-mobile">
          <a
            href="https://firebasestorage.googleapis.com/v0/b/padel-spot-1aa9f.appspot.com/o/Regolamento-di-Giustizia.pdf?alt=media&token=da992a63-f146-4ac7-8dfb-a1f6a1133730"
            target="_blank"
            class="has-text-danger-dark"
            >Regolamento di Giustizia</a
          >
        </div>
      </div>
    </div>

    <BannerCookie
      v-if="showCookieBanner"
      v-on:closed="showCookieBanner = false"
    ></BannerCookie>
  </div>
</template>

<script>
import BannerCookie from "./BannerCookie.vue";

export default {
  name: "Footer",
  data() {
    return { showCookieBanner: false };
  },
  mounted() {
    this.showCookieBanner =
      localStorage.getItem(this.$store.getters.acceptCookieKey) == null;
  },
  components: { BannerCookie },
};
</script>

<style scoped>
.my-footer {
  margin-bottom: -1rem;
}
</style>
