import Vue from 'vue'
import Vuex from 'vuex'
import M from './mutation-types'
import A from './action-types'
// modules
import centers from './modules/centers'
import firebase from '../firebase'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  updateProfile,
  getIdTokenResult,
  sendEmailVerification,
  sendPasswordResetEmail,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  reload
} from "firebase/auth";
// import { doc, onSnapshot } from "firebase/firestore";

import Emails from '../services/emails'
import Users from '../services/users'
import Generals from '../services/generals'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isRegistering: false,
    // used to know if all data from the firestore user has been loaded in the "getters.user" field
    isLoadingFirestoreUserData: false,
    // user variable
    user: {
      isLogged: false,
      data: {// firebase user auth data
        uid: null,
        displayName: null,
        email: null,
        // emailVerified: null,
        claims: {
          admin: null,
          email_verified: null// da firebase/auth
        }
      },
      // from firestore
      info: {
        latestSeenTimestamp: null
      },
      unsubscribeUpdateClaims: null
    }
  },
  getters: {
    acceptCookieKey() {
      return "accept_cookie";
    },
    regexName() {
      return new RegExp(/^[a-zA-Z'àòèéìù ]{3,50}$/, "");
    },
    regexEmail() {
      return new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/, "");
    },
    regexPassword() {
      return new RegExp(/^(?=.*[A-Za-z #.,'àòèéùì<>()/[{}?!@$%^&*-])(?=.*\d)[A-Za-z #.,'àòèéùì<>()/[{}?!@$%^&*\d]{8,}$/, "");
    },
    unsubscribeUpdateClaims(state) {
      return state.user.unsubscribeUpdateClaims;
    },
    availableReservationDaysInTheFuture() {
      return 21;
    },
    isRegistering(state) {
      return state.isRegistering;
    },
    authProvider(state) {
      return state.user.isLogged ? firebase.auth.currentUser.providerData[0]?.providerId : null;
    },
    user(state) {
      return state.user.isLogged ? state.user.data : { claims: {} };
    },
    userInfo(state) {
      return state.user.isLogged ? state.user.info : {};
    },
    isLogged(state) {
      return !!state.user.isLogged;
    },
    isAdmin(state) {
      return state.user.isLogged ? !!state.user.data.claims.admin : false;
    },
    hasAccess(state) {
      return state.user.data.claims.hasAccess;// lascio il nullo per riconoscere i momenti in cui il dato non è disponibile
    },
    // used to know if all data from the firestore user has been loaded in the "getters.user" field
    isLoadingFirestoreUserData(state) {
      return state.isLoadingFirestoreUserData;
    },
    username(state) {
      return state.user.isLogged ? (state.user.data.displayName ?? state.user.data.email) : null;
    }
  },
  mutations: {
    SET_UNSUBSCRIBE_UPDATE_CLAIMS(state, value) {
      state.user.unsubscribeUpdateClaims = value;
    },
    SET_REGISTER_STATUS(state, value) {
      state.isRegistering = value;
    },
    SET_HAS_INFO_STATUS(state, value) {
      state.isLoadingFirestoreUserData = value;
    },
    SET_LOGGED_IN(state, value) {
      state.user.isLogged = value;
    },
    SET_USER(state, {
      uid,
      displayName,
      email,
      emailVerified,
      claims: { admin, hasAccess } = {}
    } = {}) {
      state.user.data = {
        uid,
        displayName,
        email,
        emailVerified,
        claims: { admin, hasAccess }
      };
      this.commit(M.SET_LOGGED_IN, !!email);
    },
    SET_USER_INFO(state, { latestSeenTimestamp } = {}) {
      state.user.info.latestSeenTimestamp = latestSeenTimestamp;
    },
    SET_CLAIMS(state, { admin, hasAccess } = {}) {
      state.user.data.claims = { admin, hasAccess };
    }
  },
  actions: {
    async updateGenerals(_, { id, message, color }) {
      let ret = {
        success: false,
        message: ""
      };

      await Generals.update(id, { message, color })
        .then(async () => {
          ret.success = true;
        })
        .catch(() => { });
      return ret;
    },

    async reloadUser({ commit }) {
      let ret = {
        success: false,
        message: ""
      };

      commit(M.SET_HAS_INFO_STATUS, false);
      await reload(firebase.auth.currentUser)
        .then(async () => {
          commit(M.SET_USER, firebase.auth.currentUser);
          await this.dispatch(A.RELOAD_CLAIMS);
          ret.success = true;

          // aggiungo questa chiamata per ottenere i dati aggiuntivi dell'utente che sono salvati solo in firestore, e non in auth
          Users.get(firebase.auth.currentUser.uid)
            .then((firestoreUser) => {
              // console.log(firestoreUser)
              // aggiorno lo user
              commit(M.SET_USER_INFO, firestoreUser);
              commit(M.SET_HAS_INFO_STATUS, true);
            })
            .catch((err) => { console.log(err) });
        })
        .catch(() => { });
      return ret;
    },

    async reloadClaims({ commit }) {
      let ret = {
        success: false,
        message: ""
      };

      await getIdTokenResult(firebase.auth.currentUser, true)
        .then(tokenResult => {
          commit(M.SET_CLAIMS, tokenResult.claims);
        })
        .catch(() => { });
      return ret;
    },

    async login({ commit }, { email, password }) {
      let user = undefined;
      let ret = {
        success: false,
        message: ""
      };

      await signInWithEmailAndPassword(firebase.auth, email, password)
        .then(async (data) => {
          user = data.user;
          ret.success = true;
        })
        .catch((err) => {
          user = undefined;
          switch (err.code) {
            case "auth/wrong-password":
              ret.message = "Utente o Password errati";
              break;
            case "auth/invalid-email":
              ret.message = "Email non valida";
              break;
            case "auth/popup-closed-by-user":
              ret.message = "Il popup è stato chiuso dall'utente";
              break;
            case "auth/too-many-requests":
              ret.message = "Sono stati falliti troppi tentativi di accesso. Temporaneamente non sarà possibile eseguire altre richieste";
              break;
            case "auth/user-not-found":
              ret.message = "Utente non trovato";
              break;
            default:
              ret.message = "Non è stato possibile accedere a questo account, riprova";
              break;
          }
        }).finally(() => {
          // set user
          commit(M.SET_USER, user);
        });
      return ret;
    },

    async loginGoogle({ commit }) {
      // prompt login
      const provider = new GoogleAuthProvider();

      let user = undefined;
      let ret = {
        success: false,
        message: ""
      };

      await signInWithPopup(firebase.auth, provider)
        .then(async (data) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // const credential = GoogleAuthProvider.credentialFromResult(data);
          // const token = credential.accessToken;
          user = data.user;
          await getIdTokenResult(data.user).then(tokenResult => {
            user.claims = tokenResult.claims;
          });
          ret.success = true;
        })
        .catch((err) => {
          user = undefined;
          switch (err.code) {
            case "auth/popup-closed-by-user":
              ret.message = "Il popup è stato chiuso dall'utente";
              break;
            case "auth/too-many-requests":
              ret.message = "Sono stati falliti troppi tentativi di accesso. Temporaneamente non sarà possibile eseguire altre richieste.";
              break;
            default:
              ret.message = "Non è stato possibile registrare questo account, riprova";
              break;
          }
        });
      // set user
      commit(M.SET_USER, user);

      return ret;
    },

    async register({ commit }, { name, email, password }) {
      let ret = {
        success: false,
        message: ""
      };

      commit(M.SET_REGISTER_STATUS, true);

      await createUserWithEmailAndPassword(firebase.auth, email, password)
        .then(async (data) => {
          // rimando al login dopo che ho sono uscito dal Login automatico portato dalla registrazione; questo permette alla funzione cloud di avere il tempo di creare un nome
          this.dispatch(A.LOGOUT).then(
            async () => {
              await updateProfile(data.user, {
                displayName: String(name).trim(),
              })
                .then(async () => {
                  data.user.displayName = String(name).trim();

                  ret.success = !!data.user;
                  ret.message = data.user ? "Attendi..." : "Registazione fallita";

                  // await new Promise((resolve) => {// doesn't work somehow
                  // let unsubscribe = onSnapshot(doc(firebase.db, "users", data.user.uid), async (snapshot) => {
                  //   if (snapshot && snapshot.data() && !snapshot.data().displayName) {
                  //     unsubscribe();
                  if (ret.success) {
                    await Users.update(data.user.uid, { displayName: String(name).trim() });
                  }
                  commit(M.SET_REGISTER_STATUS, false);
                  // fine registrazione
                  if (ret.success) {
                    router.replace({
                      name: "Login", query: {
                        register: true
                      }
                    });
                  }
                  // resolve();
                  //   }
                  // });
                  // });
                })
                .catch(() => {
                  // console.debug(error);
                })
                .finally(() => {
                });
            })
            .finally(() => {
            });
        })
        .catch((err) => {
          commit(M.SET_REGISTER_STATUS, false);
          switch (err.code) {
            case "auth/email-already-exists":
              ret.message = "Questa email è già in uso";
              break;
            case "auth/email-already-in-use":
              ret.message = "Questa email è già in uso";
              break;
            case "auth/invalid-email":
              ret.message = "Email non valida";
              break;
            case "auth/popup-closed-by-user":
              ret.message = "Il popup è stato chiuso dall'utente";
              break;
            case "auth/too-many-requests":
              ret.message = "Sono stati falliti troppi tentativi di accesso. Temporaneamente non sarà possibile eseguire altre richieste.";
              break;
            default:
              ret.message = "Non è stato possibile registrare questo account, riprova";
              break;
          }
        }).finally(() => {
        });
      return ret;
    },

    async subscribeEmail(_, { email }) {
      let ret = {
        success: false,
        message: ""
      };

      await Emails
        .create({ email: email })
        .then(() => {
          ret.success = true;
          ret.message = "Iscrizione avvenuta con successo";
        })
        .catch((err) => {
          switch (err.code) {
            default:
              ret.message = "Si è verificato un errore, riprova";//err.message.toString().replace("Firebase: ", "");
              break;
          }
        }).finally(() => {
          // 
        });
      return ret;
    },

    async logout({ commit }) {
      let ret = {
        success: false,
        message: ""
      };

      if (this.getters.unsubscribeUpdateClaims != null) {
        this.getters.unsubscribeUpdateClaims();
        commit(M.SET_UNSUBSCRIBE_UPDATE_CLAIMS, null)
      }
      await signOut(firebase.auth)
        .then(() => {
          ret.success = true;
        })
        .catch(() => {
        }).finally(() => {
          // set user
          commit(M.SET_USER, {});
        });
      return ret;
    },

    async saveProfile() {
      let ret = {
        success: false,
        message: ""
      };

      // await signOut(firebase.auth)
      //   .then(() => {
      //     ret.success = true;
      //   })
      //   .catch(() => {
      //   }).finally(() => {
      //     // set user
      //     commit(M.SET_USER, {});
      //   });
      return ret;
    },

    async verifyEmail() {
      let ret = {
        success: false,
        message: ""
      };

      await sendEmailVerification(firebase.auth.currentUser)
        .then(async () => {
          ret.success = true;
          await Users.update(firebase.auth.currentUser.uid, { emailVerified: true })
            .then(() => {
              // i don't care
            })
            .catch(() => {
              // i don't care
            });
        })
        .catch(() => {
          ret.success = false;
        }).finally(() => {
        });
      return ret;
    },

    async reauthenticateUser(_, { password }) {
      let credential = EmailAuthProvider.credential(this.getters.user.email, password);

      return reauthenticateWithCredential(firebase.auth.currentUser, credential);
    },

    async updateUsername(_, { displayName }) {
      let ret = {
        success: false,
        message: ""
      };

      await updateProfile(firebase.auth.currentUser, { displayName: displayName.trim() })
        .then(async () => {
          await Users
            .update(this.getters.user.uid, { displayName: displayName.trim() })
            .then(() => {
              ret.success = true;
              ret.message = "Cambio nome utente avvenuto con successo";
            })
            .catch((err) => {
              switch (err.code) {
                default:
                  ret.message = "Si è verificato un errore, riprova";//err.message.toString().replace("Firebase: ", "");
                  break;
              }
            }).finally(() => {
              // 
            });
        });
      return ret;
    },

    async updatePassword(_, { oldPassword, newPassword }) {
      let ret = {
        success: false,
        message: ""
      };

      if (oldPassword && oldPassword != "") {
        if (oldPassword != newPassword) {
          this.dispatch(A.REAUTHENTICATE_USER, { password: oldPassword })
            .then(async () => {
              await updatePassword(firebase.auth.currentUser, newPassword.trim())
                .then(() => {
                  ret.success = true;
                  ret.message = "Password modificata con successo!";
                })
                .catch(() => {
                  // console.error(e);
                  ret.success = false;
                  ret.message = "Non è stato possibile modificare la password, riprova";
                }).finally(() => {
                });
            })
            .catch((err) => {
              switch (err.code) {
                case "auth/wrong-password":
                  ret.message = "Password corrente errata";
                  break;
                case "auth/too-many-requests":
                  ret.message = "Sono stati falliti troppi tentativi di seguito. Temporaneamente non sarà possibile eseguire altre richieste.";
                  break;
                default:
                  ret.message = "Non è stato possibile modificare la password, riprova";
                  break;
              }
              ret.success = false;
            });
        }
        else {
          ret.message = "La nuova password è uguale a quella vecchia!";
        }
      }
      else {
        ret.message = "Inserisci la tua password corrente";
      }
      return ret;
    },

    async resetPassword(_, { email }) {
      let ret = {
        success: false,
        message: ""
      };

      await sendPasswordResetEmail(firebase.auth, email)
        .then(() => {
          ret.success = true;
          ret.message = "La email di reset è stata inviata. Controlla la tua casella di posta";
        })
        .catch(() => {
          // console.error(e);
          ret.success = false;
          ret.message = "Non è stato possibile eseguire questa operazione, riprova";
        }).finally(() => {
        });
      return ret;
    },
  },
  modules: {
    centers
  }
})
