import firebase from "../firebase";
import { getDocs, doc, setDoc, query, where } from "firebase/firestore";

const db = firebase.collection("generals");

class GeneralsService {
  async getAllRaw() {
    return await getDocs(db);
    // return snapshot.docs.map(doc => doc.data());
  }

  async getBannerHome() {
    const snapshot = await getDocs(query(db, where("page", "==", "Home")));
    return snapshot.docs.map(doc => doc.data())[0];
  }

  async getBannerReserve() {
    const snapshot = await getDocs(query(db, where("page", "==", "Reserve")));
    return snapshot.docs.map(doc => doc.data())[0];
  }

  async getBannerVideo() {
    const snapshot = await getDocs(query(db, where("page", "==", "Video")));
    return snapshot.docs.map(doc => doc.data())[0];
  }

  async update(id, value) {
    return await setDoc(doc(db, id), value, { merge: true });
  }
}

export default new GeneralsService();