<template>
  <span>
    <div id="app" class="pt-6" v-bind:class="{
      'has-background-light':
        $route.name == 'Register' || $route.name == 'Login',
    }">
      <!-- <small v-if="env === 'development'" class="has-background-grey has-text-light has-text-left p-1" style="
        position: fixed;
        bottom: 5%;
        right: 5%;
        z-index: 100;
        max-width: 220px;
        opacity: 0.7;
      ">
      Mode: {{ env }}
      <hr class="my-1" />
      {{ $store.getters.user }}
      {{ $store.getters.userInfo }}
      <hr class="my-1" />
      Unsubscribe: {{ !!$store.getters.unsubscribeUpdateClaims }}
    </small> -->
      <NavBar class="fixed-navbar" :lastNewsTimestamp="lastNews" />
      <router-view class="py-3" />
      <Footer />
    </div>
    <notifications group="global" position="bottom right" />
  </span>
</template>

<script>
// @ is an alias to /src
import NavBar from "./components/NavBar.vue";
import Footer from "./components/Footer.vue";
import News from "./services/news";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "PadelSpot",
    // all titles will be injected into this template
    titleTemplate: "%s | PadelSpot",
    meta: [
      { "http-equiv": "Content-Type", content: "text/html; charset=utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "PadelSpot official website" },
    ],
  },
  data() {
    return {
      env: process?.env?.NODE_ENV,
      lastNews: null
    };
  },
  created() {
    News.getLatestTimestamp()
      .then((lastTimestamp) => {
        this.lastNews = lastTimestamp;
      }).catch((err) => {
        console.log(err);
      });
  },
  components: {
    NavBar,
    Footer,
  },
};
</script>

<style lang="sass">

@import 'bulma'
@import "~bulma-extensions/bulma-checkradio/dist/css/bulma-checkradio.sass"
@import "~bulma-extensions/bulma-steps/dist/css/bulma-steps.min.css"
@import "~bulma-extensions/bulma-switch/src/sass/index.sass"
@import "~bulma-extensions/bulma-divider/dist/css/bulma-divider.sass"
@import "~bulma-extensions/bulma-pageloader/dist/css/bulma-pageloader.sass"
// @import "~bulma-extensions/bulma-carousel/dist/css/bulma-carousel.min.css"
// @import "~bulma-extensions/bulma-calendar/dist/css/bulma-calendar.min.css"
</style>
<style>
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;600;700&display=swap");

html {
  display: block;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  /* overflow-y: hidden; */
  width: 100%;
}

.fixed-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 21474836;
  /* max = 2147483647 */
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

  filter: drop-shadow(1px 1px 0.5px #808080);
  -webkit-filter: drop-shadow(1px 1px 0.5px #808080);
}

.is-min-content {
  width: min-content;
}

@media only screen and (min-width: 1023px) {
  .cover-image-block {
    min-height: 280px;
    max-height: 280px;
  }
}

@media only screen and (max-width: 1023px) {
  body {
    display: block;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
  }

  #navbarMenu {
    margin: 10px 15px 0 15px;
    position: sticky;
  }

  #navbarAdmin {
    border: none !important;
  }

  #navbarProfile {
    border: none !important;
  }

  a.navbar-item.is-active {
    color: #cc0f35 !important;
  }

  a.navbar-link:not(.is-arrowless)::after {
    border-color: #cc0f35 !important;
  }

  .navbar-item {
    text-shadow: none !important;
  }

  .navbar-link {
    text-shadow: none !important;
  }

  .cover-image-block {
    height: min(500px, 80vw);
  }
}

#app {
  font-family: "Outfit", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  color: #2c3e50;
  scroll-behavior: smooth;
}

a .navbar-item .greyscale {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  background-size: cover !important;
  max-width: 220px;
  height: 80px;
}

.is-horizontal-center {
  justify-content: center;
}

.content-fullheight {
  min-height: 90vh;
}

.fixed-parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cover-image {
  object-fit: cover;
  object-position: center;
}

.is-glass {
  background: rgba(255, 255, 255, 0.67) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(7px) !important;
  -webkit-backdrop-filter: blur(7px) !important;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.78) !important;

  /* Hey browser, use your GPU */
  transform: translate3d(0, 0, 0);
}

.has-border-light {
  border: 1px solid rgba(255, 255, 255, 0.45) !important;
}

.has-border-dark {
  border: 1px solid rgba(0, 0, 0, 0.517) !important;
}

.background-text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  line-height: 1.5;
  font-weight: bold;
  /* font-size: 75vh; */
  opacity: 0.1;
  z-index: 0;
  overflow: hidden;
}

.aspect-ratio {
  aspect-ratio: 16/9;
}

.circle {
  clip-path: circle();
}

.rounded {
  border-radius: 25px !important;
}

.rounded-left {
  border-radius: 25px 0 0 25px !important;
}

.rounded-right {
  border-radius: 0 25px 25px 0 !important;
}

.rounded-top {
  border-radius: 25px 25px 0 0 !important;
}

.rounded-bottom {
  border-radius: 0 0 25px 25px !important;
}

.is-circular {
  border-radius: 50% !important;
}

.has-shadow-light {
  box-shadow: 0 8px 32px 0 rgba(207, 207, 218, 0.38) !important;
}

.has-shadow-dark {
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
}

.has-text-shadow-light {
  text-shadow: 1px 1px 14px white, 0 0 30px black, 0 0 1px black;
}

.has-text-shadow-dark {
  text-shadow: 1px 1px 7px black, 0 0 30px black, 0 0 1px black;
}

.has-no-text-shadow {
  text-shadow: none;
}

.is-grid {
  display: grid;
}

.is-absolute {
  position: absolute;
}

.vue-notification {
  padding: 12px 16px !important;
  margin: 0px 20px 20px 20px !important;

  font-size: 15px !important;

  border-radius: 8px !important;

  cursor: pointer;
}
</style>
