import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, collectionGroup } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage, ref } from "firebase/storage";

const configOptions = {
    apiKey: "AIzaSyCXhkgCTrpasnUu1PX8NH25-rSK-0Qse6k",
    authDomain: "padel-spot-1aa9f.firebaseapp.com",
    projectId: "padel-spot-1aa9f",
    storageBucket: "padel-spot-1aa9f.appspot.com",
    messagingSenderId: "660305188555",
    appId: "1:660305188555:web:1607511501081ea6e0ad4e",
    measurementId: "G-GJL5X8QG20"
};

const firebaseApp = initializeApp(configOptions);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storageDB = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp, 'europe-west1');
// connectFirestoreEmulator(db, 'localhost', 8080);
// connectFunctionsEmulator(functions, 'localhost', 5001);
// connectStorageEmulator(storageDB, 'localhost', 9199);

export default {
    firebaseApp: firebaseApp,
    auth: auth,
    db: db,
    storageDB: storageDB,
    functions: functions,
    ref: (name) => ref(storageDB, name),
    collection: (name, ...pathSegments) => collection(db, name, ...pathSegments),
    collectionGroup: (name, ...pathSegments) => collectionGroup(db, name, ...pathSegments)
};