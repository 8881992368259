import firebase from "../firebase";
import { uploadBytesResumable, listAll, getDownloadURL, deleteObject } from "firebase/storage";

const db = (name) => firebase.ref("images/" + name);
const dbSponsors = (name) => firebase.ref("sponsors/" + name);

class PhotosService {
  listAll() {
    return listAll(db(""));
  }

  getImage(name) {
    return db(name);
  }

  getDownloadURL(fullPath) {
    return getDownloadURL(firebase.ref(fullPath));
  }

  // async getAll() {
  //   const snapshot = await getDocs(db);
  //   return snapshot.docs.map(doc => doc.data());
  // }

  uploadImageBytesResumable(filename, file, metadata = undefined) {
    return uploadBytesResumable(db(filename), file, metadata);
  }

  delete(fullPath) {
    return deleteObject(firebase.ref(fullPath));
  }

  // Sponsors
  listAllSponsors() {
    return listAll(dbSponsors(""));
  }

  getImageSponsors(name) {
    return dbSponsors(name);
  }

  // async getAll() {
  //   const snapshot = await getDocs(db);
  //   return snapshot.docs.map(doc => doc.data());
  // }

  uploadImageBytesResumableSponsors(filename, file, metadata = undefined) {
    return uploadBytesResumable(dbSponsors(filename), file, metadata);
  }

  deleteSponsors(fullPath) {
    return deleteObject(firebase.ref(fullPath));
  }
}

export default new PhotosService();